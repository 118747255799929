import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { strongEmailRegex } from '@common/validation/emailRegex';
import RichText from '@components/RichText/RichText';
import type { Fields } from '@sitecore/types/GenericFormInputField';
import { InputEmail } from '@sparky';

import { FormValues, GenericFormFC, GenericFormInputProps } from '../../util';

// Important: this name maps to the field name expected by DC, so it should not just be changed
const NAME_FALLBACK = 'emailAddress';

function isGenericFormEmail(field: Fields) {
  return field?.genericInputField !== undefined;
}

const GenericFormEmailInputField: GenericFormFC<GenericFormInputProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormEmail(fields)) {
    return null;
  }

  const { hint, label, placeholder } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return (
    <InputEmail
      autoComplete="email"
      error={errors?.[name]?.message}
      hint={<RichText html={hint}></RichText>}
      label={label}
      placeholder={placeholder}
      {...register(name)}
    />
  );
};

interface EmailFieldParams {
  requiredMessage?: string;
  validationMessage?: string;
}

function getEmailFieldSchema({ requiredMessage, validationMessage }: EmailFieldParams = {}) {
  let emailFieldSchema = yup.string();

  if (requiredMessage) {
    emailFieldSchema = emailFieldSchema.required(requiredMessage);
  }

  if (validationMessage) {
    emailFieldSchema = emailFieldSchema.matches(strongEmailRegex, validationMessage);
  }

  return emailFieldSchema;
}

GenericFormEmailInputField.yupValidationScheme = (fields: Fields) => {
  const genericInputField = fields?.genericInputField?.value ?? {};
  const name = genericInputField.name || NAME_FALLBACK;

  return { [name]: getEmailFieldSchema(genericInputField) };
};

export default GenericFormEmailInputField;
