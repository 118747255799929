import * as yup from 'yup';

interface EmailValidationOptions {
  emailMessage?: string;
  requiredMessage?: string;
}

// regex can be found here https://loop.cloud.microsoft/p/eyJ1IjoiaHR0cHM6Ly9lbmVjby5zaGFyZXBvaW50LmNvbS9jb250ZW50c3RvcmFnZS9DU1BfOWVlMjQ0YmItZDliNi00M2MzLWFhYmEtZTIxNjk3M2RmMDBmP25hdj1jejBsTWtaamIyNTBaVzUwYzNSdmNtRm5aU1V5UmtOVFVDVTFSamxsWlRJME5HSmlKVEpFWkRsaU5pVXlSRFF6WXpNbE1rUmhZV0poSlRKRVpUSXhOamszTTJSbU1EQm1KbVE5WWlVeU1YVXdWR2x1Y21KYWR6QlBjWFYxU1Zkc2VqTjNSQ1UxUm1kTE56Umhka1Y1T1Vad1Ewc2xOVVo1V2pKRVpEbHVUR1JwUzFSTGJUbHFVV0oxTXpSMFpHOWpSbVZDSm1ZOU1ERk1VRkJZTjFwWU4xQkNOMU5QVlRZeVVWSkZNMUExTnpaRVdFZFROMHhMTnlaalBTVXlSZz09In0%3D?ct=1736931904056&&cid=78DECFCF-94B2-480E-87F0-898C085DBE28&LOF=1
export const strongEmailRegex = /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 * Custom email validation schema using Yup.
 *
 * The default Yup email validation is not strict enough for our requirements,
 * as it may allow certain invalid email formats to pass. To ensure more accurate
 * validation, we've added an additional regex match that enforces stricter rules
 * for email structure.
 *
 * This function should be used wherever email validation is required to maintain
 * consistency and ensure that only properly formatted email addresses are accepted.
 *
 */

export const emailValidation = ({ emailMessage, requiredMessage }: EmailValidationOptions) =>
  yup.string().required(requiredMessage).email(emailMessage).matches(strongEmailRegex, emailMessage);
